<template>
  <v-container id="data-tables-view" fluid tag="section">
    <material-card
      icon="mdi-clipboard-list"
      icon-small
      color="primary"
      title="Users"
    >
      <v-card-text>
        <input type="file" @change="readExcel" />
        <v-select
          v-model="role"
          :items="roles"
          item-text="name"
          item-value="name"
          label="Role"
          style="width: 250px"
        />
        <v-form ref="form">
          <v-row v-for="(user, i) in users" :key="i">
            <div class="mt-8">{{ i + 1 }}</div>
            <v-col>
              <v-text-field v-model="user.firstName" label="First name" />
            </v-col>
            <v-col
              ><v-text-field v-model="user.lastName" label="Last name"
            /></v-col>
            <v-col
              ><v-text-field
                v-model="user.username"
                label="username"
                :rules="validate(user.username)"
            /></v-col>
            <v-col
              ><v-text-field v-model="user.password" label="password"
            /></v-col>

            <v-icon v-if="user.isCreated === 'notCreated'" color="error" large>
              mdi-close-circle
            </v-icon>
            <v-icon
              v-else-if="user.isCreated === 'created'"
              color="primary"
              large
            >
              mdi-check-circle
            </v-icon>
            <v-btn color="error" v-else class="mt-5" @click="removeUser(user)">
              Remove
            </v-btn>
          </v-row>
        </v-form>
        <v-btn
          color="primary"
          v-if="users && users.length > 0"
          @click="addUsers()"
        >
          Add Users
        </v-btn>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import readXlsxFile from "read-excel-file";

export default {
  name: "CreateUsers",
  data: () => ({
    file: null,
    users: [],
    role: "",
    roles: [],
  }),
  mounted() {
    this.$http.get("/Roles").then((response) => {
      let roles = response.data.data;
      if (roles) {
        this.roles = roles.map((x) => x.name).filter((x) => x !== "SuperUser");
      }
    });
  },
  methods: {
    validate(username) {
      let users = this.users.filter((x) => x.username == username);
      return [() => !(username && users.length > 1) || "Duplicate username"];
    },
    readExcel(event) {
      this.users = [];
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        readXlsxFile(this.file).then((rows) => {
          rows.forEach((row, i) => {
            if (i != 0) {
              if (row[0] && row[0].length > 0) {
                let names = row[0].split(" ");
                let firstName = names && names.length > 0 ? names[0] : "";
                let lastName = names && names.length > 1 ? names[1] : "";
                let username =
                  (firstName && firstName.length > 4
                    ? firstName.substring(0, 4)
                    : firstName) +
                  (lastName && lastName.length > 1
                    ? lastName.substring(0, 1)
                    : "");
                username = username.toLowerCase();
                let password = username;

                this.users.push({
                  id: i,
                  names: row[0],
                  firstName,
                  lastName,
                  username,
                  password,
                  conformPassword: password,
                  email: username + "@myorders.com.au",
                  isCreated: "waiting",
                });
              }
            }
          });
        });
        setTimeout(() => {
          this.$refs.form.validate();
        }, 3000);
      }
    },
    addUsers() {
      if (this.$refs.form.validate()) {
        this.users.forEach(async (x) => {
          if (this.role) {
            x.role = this.role;
          }
          let response = await this.$http.post("Users", x);
          if (response.data.success) {
            x.isCreated = "created";
          } else {
            x.isCreated = "notCreated";
            console.log("Failed User: ", x, response.data.message);
          }
        });
      }
    },
    removeUser(user) {
      let index = this.users.findIndex((x) => x.id === user.id);
      this.users.splice(index, 1);
    },
  },
};
</script>
